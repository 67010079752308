import hostName from './host';

export function isDev(): boolean {
    return /172.20|192.168|localhost|127.0|.ttt|isDev=true/ig.test(window.location.href);
}

export function setHost(host: string): string {
    const hostConfig = host.match(/^(.*)(?=:)/ig) || [];
    const hostObj:any = isDev() ? hostName['development'] : hostName['production'];
    return hostObj[hostConfig[0]] || '';
}

export function setPath(path:string) :string {
    const pathName = path.match(/(\/\/)=?.*/ig) || [];
    pathName[0] = pathName[0] || '';
    return pathName[0].substr(2) || '';
}

export function sgin():number{
    const timeStamp = new Date().getTime();
    return timeStamp;
}