import axios from 'axios';
import { setHost, setPath } from './index';
import params from './params';

const Service = axios.create({
    timeout: 5000,
});


Service.interceptors.request.use(
    config => {
        config.url = setHost(config.url || '') + setPath(config.url || '');
        config.params = { ...config.params, ...params };
        // console.log(config)
        // config.data = { ...config.data, data }
        // do something before request is sent

        // if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        // config.headers['X-Token'] = getToken()
        // }
        return config;
    },
    error => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }

);


Service.interceptors.response.use(
    (    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */

        /**
             * Determine the request status by custom code
             * Here is just an example
             * You can also judge the status by HTTP Status Code
             */
        response: { data: any; }) => {
        const res = response.data;
            
        return res;
    },
    (error: string) => {
        // console.log('err' + error) // for debug
        //   Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        //   })
        return Promise.reject(error);
    }
);


export default Service;

