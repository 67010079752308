import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css';
createApp(App)
    .directive('focus', {
        mounted (el:HTMLElement) {
            console.log(el);
        }
    })
    .directive('a', {
        created () {
            console.log(1);
        }
    })
    .use(store).use(router).use(ElementPlus).mount('#app');
