import { createStore } from 'vuex';


interface UserState {
    token: string
}

// 用户信息
const userModule = {
    state: (): UserState => ({
        token: ''
    }),
    mutations: {
        setToken(state: { token: string }, token: string) {
            state.token = token;
        }
    }
};


interface SystemState {
    color: string
}

//  设备信息
const systemModule = {
    state: (): SystemState => ({
        color: ''
    }),
    mutations: {

    }
};

interface RouteState {
    id: number,
    name: string,
    path: string,
}

// 路由
const routeModule = {
    state: (): Array<RouteState> => [],
    mutations: {
        setRoute(state: Array<RouteState>, route: Array<RouteState>) {
            Object.assign(state, route);
        }
    }
};

export default createStore({

    modules: {
        userModule,
        systemModule,
        routeModule
    }
});
