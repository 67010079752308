import Cookies from 'js-cookie';

const TokenKey = 'admin-Token';

export function getToken():any {
    return Cookies.get(TokenKey);
}

export function setToken(token: string):void {
    Cookies.set(TokenKey, token, {
        expires: 3
    });
}

export function removeToken():void {
    Cookies.remove(TokenKey);
}
