import request from '@/utils/request';
import qs from 'qs';

interface UserInfoData {
    data: {
        avatarUrl: string
        catId: string
        createTime: string
        id: number
        lastTime: string
        name: string
        nickName: string
        phone: string
        power: number
        sex: number
    }
}

export function getUser(): Promise<UserInfoData> {
    return request({
        url: 'cat://api/admin/user-info.htm',
    });
}

interface Login {
    phone: number,
    password: string
}

interface LoginData {
    data: {
        token: string
    }
}

export function login(data: Login): Promise<LoginData> {
    return request({
        url: 'cat://api/admin/login.htm',
        method: 'post',
        data: qs.stringify(data)
    });
}

interface RouteList {
    data: [{
        id: number,
        name: string,
        path: string,
        icon: string,
        keepAlive: number
    }]
}
export function routeList(): Promise<RouteList> {
    return request({
        url: 'cat://api/admin/route-list.htm',
    });
}

interface UserList {
    data: [
        {
            id: number,
            nickName: string,
            phone: number,
            pwd: string,
            token: string,
            sex: number,
            createTime: Date,
            catId: string,
            lastTime: Date,
            name: string,
            avatarUrl: string,
            power: number
        }
    ]
}
export function getUserList(): Promise<UserList> {
    return request({
        url: 'cat://api/admin/get-user-list.htm'
    });
}

interface View {
    data: {
        id: number,
        nickName: string,
        phone: number,
        pwd: string,
        token: string,
        sex: number,
        createTime: Date,
        catId: string,
        lastTime: Date,
        name: string,
        avatarUrl: string,
        power: number
    }

}
interface ViewData {
    id: number
}
export function getUserView(data: ViewData): Promise<View> {
    return request({
        url: 'cat://api/admin/get-user-view.htm',
        params: data
    });
}