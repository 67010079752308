import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { getToken } from '@/utils/auth';
import { routeList } from '@/api/api';


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',

        name: 'Home',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/Register.vue')
    },
    {
        name: '404',
        path: '/:pathMatch(.*)',
        component: () => import('@/views/404.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

interface RoutesList {
    id: number,
    name: string,
    path: string,
    icon: string,
    keepAlive: number
}

const routesList: Array<RoutesList> = JSON.parse(window.localStorage.getItem('routeList') || '[]') || [];


if (routesList.length) {
    routesList.filter((item) => {
        router.addRoute('Home', {
            name: item.path,
            path: '/' + item.path,
            meta: {
                name: item.name,
                keepAlive: !item.keepAlive
            },
            component: () => import(`@/components/${(item.path).substr(0, 1).toLocaleUpperCase()}${(item.path).substr(1)}.vue`)
        });
    });
}

// 动态添加路由
routeList().then(res => {
    res = res || {};
    res.data = res.data || [];
    res.data.filter((item) => {
        router.addRoute('Home', {
            name: item.path,
            path: '/' + item.path,
            meta: {
                name: item.name,
                keepAlive: !item.keepAlive
            },
            component: () => import(`@/components/${(item.path).substr(0, 1).toLocaleUpperCase()}${(item.path).substr(1)}.vue`)
        });
    });
    window.localStorage.setItem('routeList', JSON.stringify(res.data || '[]'));
});


router.beforeEach((to, from, next) => {
    const token = getToken();

    if (!token) {
        if (to.path !== '/login') {
            next('/login');
        }
    }

    next();
});

export default router;
